/* eslint-disable import/max-dependencies */
import {
  configureStore, type Reducer, type AnyAction, createImmutableStateInvariantMiddleware,
} from "@reduxjs/toolkit";
import {
  tableReducer,
  initTableState,
  loadingReducer,
  initLoadingState,
  notificationsReducer,
  initNotificationsState,
  errorsReducer,
  initErrorsState,
} from "@fas/ui-framework";
import {
  type TypedUseSelectorHook,
  useSelector as useReduxSelector,
  useDispatch as useReduxDispatch,
} from "react-redux";
import createSagaMiddleware from "redux-saga";
import mainSaga from "./saga";
import {
  ADDITIONAL_POSTBACKS_AFFILIATES_TABLE_KEY,
  INFO_POSTBACKS_AFFILIATES_TABLE_KEY,
  IO_AFFILIATES_TABLE_KEY,
  PENDING_AFFILIATES_TABLE_KEY,
  PROFILE_ALL_AFFILIATES_TABLE_KEY,
  PROFILE_MY_AFFILIATES_TABLE_KEY,
  RESPONDED_AFFILIATES_TABLE_KEY,
  SUBRATE_AFFILIATES_TABLE_KEY,
  SUBRATE_AFFILIATES_ADV_TABLE_KEY,
  EXT_RATE_AFFILIATES_TABLE_KEY,
  OFFERWALL_TABLE_KEY,
  PENDING_INT_APPLICATIONS_TABLE_KEY,
  SEARCH_EXT_APPLICATIONS_TABLE_KEY,
  SEARCH_INT_APPLICATIONS_TABLE_KEY,
  PENDING_EXT_APPLICATIONS_TABLE_KEY,
  SEARCH_EXT_APPLICATIONS_AFFILIATE_TABLE_KEY,
  SEARCH_EXT_TL_APPLICATIONS_TABLE_KEY,
  SEARCH_EXT_TL_APPLICATIONS_AFFILIATE_TABLE_KEY,
  PENDING_EXT_TL_APPLICATIONS_TABLE_KEY,
  SHAVE_MODIFICATIONS_TABLE_KEY,
  OFFERS_TABLE_KEY,
  ADVERTISER_OFFERS_TABLE_KEY,
  ADVERTISER_DOMAIN_VALIDATION_TABLE_KEY,
  INT_OFFERS_TABLE_KEY,
  ALL_PENDING_AFFILIATES_TABLE_KEY,
  ALL_RESPONDED_AFFILIATES_TABLE_KEY,
  PAYMENT_UNAVAILABLE_MY_AFFILIATES_TABLE_KEY,
  INVOICE_HISTORY_AFFILIATES_TABLE_KEY,
  AUTODECLINE_RULES_TABLE_KEY,
  INT_OFFERS_AES_TABLE_KEY,
  EXT_RATE_AFFILIATES_ADV_TABLE_KEY,
  PROFILE_ALL_ADVERTISERS_TABLE_KEY,
  PROFILE_MY_ADVERTISERS_TABLE_KEY,
  CR_BOOSTER_TABLE_KEY,
  PENDING_AFFILIATES_TABLE_KEY_ADV,
  RESPONDED_AFFILIATES_TABLE_KEY_ADV, PROFILE_AD_NETWORK_TABLE_KEY,
  AFFILIAT_CUSTOM_LINK_TABLE_KEY,
  AFFILIAT_SMART_OFFER_LINK_TABLE_KEY,
  ADVERTISER_DOMAINS_TABLE_KEY,
  CHANGE_HISTORY_TABLE_KEY,
} from "../../helpers/constants/affiliates";
import { defaultSubrateFilters } from "./Subrates/defaultSubrateFilters";
import { defaultSubrateFilters as defaultSubrateFiltersAdv } from "./SubratesAdv/defaultSubrateFilters";
import { defaultExtRatesFilters } from "./ExtRates/useAffiliateExtRates";
import { defaultApplicationIntFilters } from "./AffiliateAdminPanelFeatures/SearchIntApplications/useSearchIntApplications";
import { defaultApplicationExtFilters } from "./AffiliateAdminPanelFeatures/SearchExtApplications/useSearchExtApplications";
import { defaultExtRatesFilters as defaultExtRatesFiltersAdv } from "./ExtRatesAdv/useAffiliateExtRates";
import {
  defaultApplicationAffiliateExtFilters,
} from "./AffiliateAdminPanelFeatures/SearchExtAffiliateApplications/useSearchExtApplications";
import { defaultTLApplicationExtFilters } from "./AffiliateAdminPanelFeatures/TLSearchExtApplications/useSearchExtApplications";
import {
  defaultTLApplicationAffiliateExtFilters,
} from "./AffiliateAdminPanelFeatures/TLSearchExtAffiliateApplications/useSearchExtApplications";
import { defaultShaveModificationsFilters } from "./ShaveModifierSystem/useShaveModifications";
import { defaultIntOffersFilters } from "./Offers/IntSearchOffersBySite/useOffers";
import { defaultOffersFilters } from "./Offers/SearchOffers/useOffers";
import { defaultAutodeclineRulesFilters } from "./Tools/AutodeclineRules/defaultAutodeclineRulesFilters";
import { defaultIntOffersAesFilters } from "./OffersAes/IntSearchOffers/useOffers";
import { defaultCrBoosterFilters } from "./CrBooster/defaultCrBoosterFilters";
import {
  defaultAdvertiserDomainValidationFilters,
} from "./ManageAdvertiser/AdvertiserDomainValidation/useAdvertiserDomainValidation";

const sagaMiddleware = createSagaMiddleware();

export const preloadedState = {
  notifications: initNotificationsState(),
  errors: initErrorsState(),
  loading: initLoadingState(),
  tables: initTableState({
    [CHANGE_HISTORY_TABLE_KEY]: {},
    [ADVERTISER_DOMAINS_TABLE_KEY]: {},
    [PENDING_AFFILIATES_TABLE_KEY]: {
      pageSize: 20,
    },
    [PENDING_AFFILIATES_TABLE_KEY_ADV]: {},
    [ALL_PENDING_AFFILIATES_TABLE_KEY]: {
      pageSize: 20,
    },
    [RESPONDED_AFFILIATES_TABLE_KEY]: {
      pageSize: 20,
    },
    [RESPONDED_AFFILIATES_TABLE_KEY_ADV]: {},
    [ALL_RESPONDED_AFFILIATES_TABLE_KEY]: {
      pageSize: 20,
    },
    [SUBRATE_AFFILIATES_TABLE_KEY]: {
      filters: defaultSubrateFilters,
    },
    [SUBRATE_AFFILIATES_ADV_TABLE_KEY]: {
      filters: defaultSubrateFiltersAdv,
    },
    [AUTODECLINE_RULES_TABLE_KEY]: {
      filters: defaultAutodeclineRulesFilters,
    },
    [PROFILE_MY_AFFILIATES_TABLE_KEY]: {
      pageSize: 20,
    },
    [PROFILE_ALL_AFFILIATES_TABLE_KEY]: {
      pageSize: 20,
    },
    [PROFILE_ALL_ADVERTISERS_TABLE_KEY]: {},
    [ADVERTISER_OFFERS_TABLE_KEY]: {},
    [ADVERTISER_DOMAIN_VALIDATION_TABLE_KEY]: {
      filters: defaultAdvertiserDomainValidationFilters,
    },
    [PROFILE_MY_ADVERTISERS_TABLE_KEY]: {},
    [IO_AFFILIATES_TABLE_KEY]: {},
    [PAYMENT_UNAVAILABLE_MY_AFFILIATES_TABLE_KEY]: {},
    [INVOICE_HISTORY_AFFILIATES_TABLE_KEY]: {},
    [EXT_RATE_AFFILIATES_TABLE_KEY]: {
      filters: defaultExtRatesFilters,
    },
    [EXT_RATE_AFFILIATES_ADV_TABLE_KEY]: {
      filters: defaultExtRatesFiltersAdv,
    },
    [SHAVE_MODIFICATIONS_TABLE_KEY]: {
      filters: defaultShaveModificationsFilters,
    },
    [AFFILIAT_CUSTOM_LINK_TABLE_KEY]: {},
    [AFFILIAT_SMART_OFFER_LINK_TABLE_KEY]: {},
    [ADDITIONAL_POSTBACKS_AFFILIATES_TABLE_KEY]: {},
    [INFO_POSTBACKS_AFFILIATES_TABLE_KEY]: {},
    [OFFERWALL_TABLE_KEY]: {},
    [PENDING_INT_APPLICATIONS_TABLE_KEY]: {},
    [SEARCH_INT_APPLICATIONS_TABLE_KEY]: {
      filters: defaultApplicationIntFilters,
    },
    [PENDING_EXT_APPLICATIONS_TABLE_KEY]: {},
    [PENDING_EXT_TL_APPLICATIONS_TABLE_KEY]: {},
    [PROFILE_AD_NETWORK_TABLE_KEY]: {},
    [SEARCH_EXT_APPLICATIONS_TABLE_KEY]: {
      filters: defaultApplicationExtFilters,
    },
    [SEARCH_EXT_TL_APPLICATIONS_TABLE_KEY]: {
      filters: defaultTLApplicationExtFilters,
    },
    [SEARCH_EXT_APPLICATIONS_AFFILIATE_TABLE_KEY]: {
      filters: defaultApplicationAffiliateExtFilters,
    },
    [SEARCH_EXT_TL_APPLICATIONS_AFFILIATE_TABLE_KEY]: {
      filters: defaultTLApplicationAffiliateExtFilters,
    },
    [OFFERS_TABLE_KEY]: {
      filters: defaultOffersFilters,
    },
    [INT_OFFERS_TABLE_KEY]: {
      filters: defaultIntOffersFilters,
    },
    [INT_OFFERS_AES_TABLE_KEY]: {
      filters: defaultIntOffersAesFilters,
    },
    [CR_BOOSTER_TABLE_KEY]: {
      filters: defaultCrBoosterFilters,
    },
  }),
};

export const store = configureStore({
  reducer: {
    notifications: notificationsReducer as Reducer<unknown, AnyAction>,
    errors: errorsReducer as Reducer<unknown, AnyAction>,
    loading: loadingReducer as Reducer<unknown, AnyAction>,
    tables: tableReducer as Reducer<unknown, AnyAction>,
  },
  preloadedState,
  middleware: [
    createImmutableStateInvariantMiddleware({}),
    sagaMiddleware,
  ],
});

sagaMiddleware.run(mainSaga);

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useDispatch = () => useReduxDispatch<AppDispatch>();
export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;
