/* eslint-disable import/max-dependencies */
// @flow
import React, {
  type StatelessFunctionalComponent,
} from "react";
import environment from "environment";
import type {
  Url,
  CollapseItem,
  Breadcrumb,
} from "@fas/ui-core/lib/AppNavbar";
import { AppNavbar } from "@fas/ui-core";
import { useLocation } from "react-router-dom";
import type { Permission } from "@fas/ui-framework/lib/services/user/types/user.types";
import { userService } from "../../services/user";
import { userApiService } from "../../services/userApi";
import getBreadcrumbs from "../../services/breadcrumbs/getBreadcrumbs";
import breadcrumbsMap from "../../services/breadcrumbs/breadcrumbsMap";
import appLinks from "./appLinks";

const navigationLinks = [
  {
    url: "/",
    text: "Dashboard",
  },
  {
    text: "Affiliates management",
    links: [
      {
        url: "/manageAffiliate",
        text: "Manage affiliates",
        permissions: [],
      },
      {
        url: "/payments/paymentInfo",
        text: "Affiliate payment info",
        permissions: [{ path: "/api/v1/cpa/affiliate", method: "POST" }],
      },
      {
        url: "/payoutTerms",
        text: "Payout terms",
        permissions: [{ path: "/api/v1/payoutTerms/filter", method: "POST" }],
      },
    ],
  },
  {
    text: "Offers management",
    links: [
      {
        url: "/cpaOffers",
        text: "Cpa offers",
        permissions: [],
      },
      {
        url: "/topOffers",
        text: "Top offers",
        permissions: [{ path: "/api/v1/topOffers/filters", method: "POST" }],
      },
    ],
  },
  {
    text: "Tools",
    links: [
      {
        url: "/subrates",
        text: "Subrates",
        permissions: [{ path: "/api/v1/dictionary/subrate", method: "POST" }],
      },
    ],
  },
  {
    text: "SMU",
    links: [
      {
        url: "/smuProductCompany",
        text: "SMU Product Companies",
        permissions: [{ path: "/api/v1/bank/companies", method: "GET" }],
      },
      {
        url: "/smuDepartments",
        text: "SMU Departments",
        permissions: [{ path: "/api/v1/bank/departments", method: "GET" }],
      },
      {
        url: "/smuBrands",
        text: "SMU Brands",
        permissions: [{ path: "/api/v1/bank/brands", method: "GET" }],
      },
    ],
  },
  {
    text: "Potential partners management",
    links: [
      {
        url: "/potentialPartner",
        text: "Potential partners",
        permissions: [{ path: "/api/v1/potentialPartner", method: "GET" }],
      },
      {
        url: "/advertiserInvoices",
        text: "Advertiser invoices",
        permissions: [{ path: "/api/v1/cpa/advertiser/invoices", method: "GET" }],
      },
    ],
  },
  {
    url: "/redefinedUtmCampaign",
    text: "Redefined utm_campaign",
    permissions: [{ path: "/api/v1/generatedUtmCampaign", method: "GET" }],
  },
  {
    text: "CRM",
    links: [
      {
        url: "/crm/mails",
        text: "Mails",
        permissions: [{ path: "/api/v1/crm/mail/filter", method: "POST" }],
      },
      {
        url: "/crm/templates",
        text: "Templates",
        permissions: [{ path: "/api/v1/template/mailTemplate/filter", method: "POST" }],
      },
    ],
  },
];

const AppHeader: StatelessFunctionalComponent<{}> = () => {
  const location = useLocation();
  const filterByPermission = ({ permissions = [] }): boolean => userService.can(permissions);
  const mapToUrl = ({ url, text }) => ({ url, text });

  const menuLinks: Array<Url | CollapseItem> = navigationLinks.filter(filterByPermission)
    .map(({ url = "", text, links }: { url?: string, text: string, links?: Array<{url: string, text: string, permissions: Permission[]}>}): Url | CollapseItem => {
      if (links) {
        return ({ text, links: links.filter(filterByPermission).map(mapToUrl) });
      }

      return ({ text, url });
    })
    .filter((item: Url | CollapseItem): boolean => !item.links || item.links.length !== 0);

  const breadcrumbs: Breadcrumb[] = getBreadcrumbs(location.pathname, breadcrumbsMap);

  const handleLogout: () => void = () => {
    userApiService.logout(environment.endpoints.get.logout);
  };

  return (
    <AppNavbar
      appName="CPA Admin"
      menuLinks={menuLinks}
      appLinks={appLinks}
      breadcrumbs={breadcrumbs}
      onLogout={handleLogout}
    />
  );
};

export default AppHeader;
