// @flow
import React from "react";
import { NavLink } from "react-router-dom";
import { Link } from "@mui/material";
import { getWithParentLinking } from "./util";

export default getWithParentLinking({
  path: "/",
  redirect: "/",
  title: "Dashboard",
  component: <Link component={NavLink} underline="hover" to="/">Dashboard</Link>,
  children: [
    { path: "", title: "", children: [] },
    {
      path: "/adNetwork/profiles/*",
      title: "AdNetwork profiles table",
    },
    {
      path: "/adNetwork/register/*",
      title: "Register new adNetwork",
    },
    {
      path: "/adNetwork/profile/:adNetworkId/view",
      title: "Profile adNetwork",
    },
    {
      path: "/advertiser/domainValidation",
      title: "Advertiser domain validation",
    },
    {
      path: "/advertiser/profiles/*",
      title: "Advertiser profiles table",
    },
    {
      path: "/advertiser/register/*",
      title: "Register new advertiser",
    },
    {
      path: "/advertiser/profile/:hash/view",
      title: "Profile advertiser",
    },
    {
      path: "/advertiser/profile/:hash/domains",
      title: "Advertiser domains",
    },
    {
      path: "/advertiser/profile/:hash/postbacks",
      title: "Postbacks",
    },
    {
      path: "/management/managerRules/*",
      title: "Managers rules",
    },
    {
      path: "/tools/modifications",
      title: "Modifications",
    },
    {
      path: "/tools/offerCampaigns",
      title: "Offer campaigns",
    },
    {
      path: "/tools/resApi",
      title: "Test ResAPI",
    },
    {
      path: "/tools/tdsApi",
      title: "Test TdsAPI",
    },
    {
      path: "/tools/autodeclineRules",
      title: "Autodecline rules",
    },
    {
      path: "/offers/searchIntOffers",
      title: "Find internal offers",
    },
    {
      path: "/offers/searchExtOffers",
      title: "Find external offers",
    },
    {
      path: "/offers/bulkOfferLinks",
      title: "Bulk offer links modification",
    },
    {
      path: "/offers",
      redirect: "/offers/searchExtOffers",
      title: "Find external offers",
      component: <Link component={NavLink} underline="hover" to="/offers/searchExtOffers">Find external offers</Link>,
      children: [
        {
          path: "/offer/ext/:offerId/info",
          title: "View offer info",
        },
        {
          path: "/offer/ext/createCpa",
          title: "Create ext cpa offer",
        },
        {
          path: "/offer/ext/createCpa/:offerId",
          title: "Clone ext cpa offer",
        },
        {
          path: "/offer/ext/createCpc",
          title: "Create ext cpc offer",
        },
        {
          path: "/offer/ext/createCpc/:offerId",
          title: "Clone ext cpc offer",
        },
      ],
    },
    {
      path: "/offers/search",
      title: "Find specific offer",
    },
    {
      path: "/offers",
      redirect: "/offers/searchIntOffers",
      title: "Find internal offers",
      component: <Link component={NavLink} underline="hover" to="/offers/searchIntOffers">Find internal offers</Link>,
      children: [
        {
          path: "/offer/int/:offerId/info",
          title: "View offer info",
        },
      ],
    },
    {
      path: "/dashboard",
      title: "",
    },
    {
      path: "/smuProductCompany",
      redirect: "/smuProductCompany",
      title: "SMU Product Companies",
      children: [
        {
          path: "/smuProductCompany/create",
          title: "Create product company",
        },
        {
          path: "/smuProductCompany/:id",
          title: ({ params }) => `Edit product company: ${String(params.id)}`,
        },
      ],
    },
    {
      path: "/smuDepartments",
      redirect: "/smuDepartments",
      title: "SMU Departments",
      children: [
        {
          path: "/smuDepartments/:id",
          title: ({ params }) => `Edit department: ${String(params.id)}`,
        },
      ],
    },
    {
      path: "/smuBrands",
      redirect: "/smuBrands",
      title: "SMU Brands",
      children: [
        {
          path: "/smuBrands/create",
          title: "Create brands",
        },
        {
          path: "/smuBrands/:id",
          title: ({ params }) => `Edit brands: ${String(params.id)}`,
        },
      ],
    },
    {
      path: "/historyChanges/*",
      title: "History changes",
    },
    {
      path: "/affiliates",
      title: "",
      children: [
        {
          path: "/affiliates/approve",
          title: "Affiliate approve",
        },
        {
          path: "/affiliates/approve/pending",
          title: "Pending affiliates",
        },
        {
          path: "/affiliates/approve/responded",
          title: "Responded affiliates",
        },
        {
          path: "/affiliates/approve/autodeclined",
          title: "Autodeclined affiliates",
        },
        {
          path: "/affiliates/paymentUnavailableList",
          title: "Payment unavailable affiliates",
        },
        {
          path: "/affiliates/approve/allPending",
          title: "All pending affiliates",
        },
        {
          path: "/affiliates/approve/allResponded",
          title: "All responded affiliates",
        },
        {
          path: "/affiliates/adNetwork/profiles/*",
          title: "AdNetwork profiles table",
        },
        {
          path: "/affiliates/adNetwork/register/*",
          title: "Register new adNetwork",
        },
        {
          path: "/affiliates/adNetwork/profile/:adNetworkId/view",
          title: "Profile adNetwork",
        },
        {
          path: "/affiliates/profiles/*",
          title: "Affiliate profiles table",
        },
        {
          path: "/affiliates/advertiser/domainValidation",
          title: "Advertiser domain validation",
        },
        {
          path: "/affiliates/advertiser/profiles/*",
          title: "Advertiser profiles table",
        },
        {
          path: "/affiliates/advertiser/register/*",
          title: "Register new advertiser",
        },
        {
          path: "/affiliates/advertiser/profile/:hash/view",
          title: "Profile advertiser",
        },
        {
          path: "/affiliates/advertiser/profile/:hash/domains",
          title: "Advertiser domains",
        },
        {
          path: "/affiliates/advertiser/profile/:hash/postbacks",
          title: "Postbacks",
        },
        {
          path: "/affiliates/profile/:affiliateId/view",
          title: "Profile affiliate",
        },
        {
          path: "/affiliates/profile/:affiliateId/subrates",
          title: "Subrates",
        },
        {
          path: "/affiliates/profile/:affiliateId/extRates",
          title: "Ext rates",
        },
        {
          path: "/affiliates/profile/:affiliateId/postbacks",
          title: "Postbacks",
        },
        {
          path: "/affiliates/profile/:affiliateId/payoutHistory",
          title: "Payout history",
        },
        {
          path: "/affiliates/profile/:affiliateId/crbooster",
          title: "CR booster",
        },
        {
          path: "/affiliates/profile/:affiliateId/links",
          title: "Affiliate links",
        },
        {
          path: "/affiliates/register",
          title: "Register new affiliate",
        },
        {
          path: "/affiliates/affAdminFeatures/applications/pending",
          title: "Pending applications",
        },
        {
          path: "/affiliates/affAdminFeatures/applications/search",
          title: "Search applications",
        },
        {
          path: "/affiliates/affAdminFeatures/manageOfferwall",
          title: "Manage offerwall",
        },
        {
          path: "/affiliates/affAdminFeatures/extApplications/pending",
          title: "Pending ext applications",
        },
        {
          path: "/affiliates/affAdminFeatures/extApplications/searchAffiliates",
          title: "Search ext applications",
        },
        {
          path: "/affiliates/affAdminFeatures/extApplications/search",
          title: "Search ext affiliate applications",
        },
        {
          path: "/affiliates/affAdminFeatures/extTLApplications/pending",
          title: "Pending ext applications",
        },
        {
          path: "/affiliates/affAdminFeatures/extTLApplications/searchAffiliates",
          title: "Search ext applications",
        },
        {
          path: "/affiliates/affAdminFeatures/extTLApplications/search",
          title: "Search ext affiliate applications",
        },
        {
          path: "/affiliates/management/managerRules/*",
          title: "Managers rules",
        },
        {
          path: "/affiliates/tools/modifications",
          title: "Modifications",
        },
        {
          path: "/affiliates/tools/offerCampaigns",
          title: "Offer campaigns",
        },
        {
          path: "/affiliates/tools/resApi",
          title: "Test ResAPI",
        },
        {
          path: "/affiliates/tools/tdsApi",
          title: "Test TdsAPI",
        },
        {
          path: "/affiliates/tools/autodeclineRules",
          title: "Autodecline rules",
        },
        {
          path: "/affiliates/offers/searchIntOffers",
          title: "Find internal offers",
        },
        {
          path: "/affiliates/offers/searchExtOffers",
          title: "Find external offers",
        },
        {
          path: "/affiliates/offers/bulkOfferLinks",
          title: "Bulk offer links modification",
        },
        {
          path: "/affiliates",
          redirect: "/affiliates/offers/searchExtOffers",
          title: "Find external offers",
          children: [
            {
              path: "/affiliates/offer/ext/:offerId/info",
              title: "View offer info",
            },
            {
              path: "/affiliates/offer/ext/createCpa",
              title: "Create ext cpa offer",
            },
            {
              path: "/affiliates/offer/ext/createCpa/:offerId",
              title: "Clone ext cpa offer",
            },
            {
              path: "/affiliates/offer/ext/createCpc",
              title: "Create ext cpc offer",
            },
            {
              path: "/affiliates/offer/ext/createCpc/:offerId",
              title: "Clone ext cpc offer",
            },
          ],
        },
        {
          path: "/affiliates/offers/search",
          title: "Find specific offer",
        },
        {
          path: "/affiliates",
          redirect: "/affiliates/offers/searchIntOffers",
          title: "Find internal offers",
          children: [
            {
              path: "/affiliates/offer/int/:offerId/info",
              title: "View offer info",
            },
          ],
        },
        {
          path: "/affiliates/affAdminFeatures/topOffers",
          title: "Top offers",
        },
        {
          path: "/affiliates/affAdminFeatures/topOffers",
          title: "Top offers",
        },
        {
          path: "/affiliates/affAdminFeatures/smartOffers",
          title: "Manage smart offers",
        },
      ],
    },
    {
      path: "/manageAffiliate",
      redirect: "/manageAffiliate",
      title: "Affiliates",
      children: [
        {
          path: "/manageAffiliate/create",
          title: "Affiliate create",
        },
        {
          path: "/manageAffiliate/edit/:id",
          title: ({ params }) => `Affiliate edit ${String(params.id)}`,
        },
        {
          path: "/manageAffiliate/edit/:id/:tab",
          title: ({ params }) => `Affiliate edit ${String(params.id)}`,
        },
      ],
    },
    {
      path: "/payments/paymentInfo",
      redirect: "/payments/paymentInfo",
      title: "Payment info",
    },
    {
      path: "/payoutTerms",
      redirect: "/payoutTerms",
      title: "Payout terms",
      children: [
        {
          path: "/payoutTerms/create",
          title: "Payout terms create",
        },
        {
          path: "/payoutTerms/:id",
          title: "Payout terms edit",
        },
      ],
    },
    {
      path: "/cpaOffers",
      redirect: "/cpaOffers",
      title: "Cpa offers",
      children: [
        {
          path: "/cpaOffers/add",
          title: "Cpa offers create",
        },
        {
          path: "/cpaOffers/campaigns",
          title: "Cpa offer campaigns",
        },
        {
          path: "/cpaOffers/:id",
          title: ({ params }) => `Cpa offers edit ${String(params.id)}`,
        },
        {
          path: "/cpaOffers/:id/clone",
          title: ({ params }) => `Cpa offers clone ${String(params.id)}`,
        },
      ],
    },
    {
      path: "/topOffers",
      redirect: "/topOffers",
      title: "Top offers",
      children: [
        {
          path: "/topOffers/create",
          title: "Top offers create",
        },
        {
          path: "/topOffers/:id",
          title: ({ params = {} }) => `Top offers edit ${String(params.id)}`,
        },
      ],
    },
    {
      path: "/subrates",
      redirect: "/subrates",
      title: "Subrates",
      children: [
        {
          path: "/subrates/create",
          title: "Subrates create",
        },
        {
          path: "/subrates/edit/:id",
          title: ({ params = {} }) => `Subrates edit ${String(params.id)}`,
        },
        {
          path: "/subrates/info",
          title: "Subrates info",
        },
      ],
    },
    {
      path: "/potentialPartner",
      redirect: "/potentialPartner",
      title: "Potential partners",
      children: [
        {
          path: "/potentialPartner/create",
          title: "Potential partner create",
        },
        {
          path: "/potentialPartner/:id",
          title: ({ params = {} }) => `Potential partner edit ${String(params.id)}`,
        },
      ],
    },
    {
      path: "/advertiserInvoices",
      redirect: "/advertiserInvoices",
      title: "Advertiser invoices",
      children: [
        {
          path: "/advertiserInvoices/create",
          title: "Advertiser invoices create",
        },
        {
          path: "/advertiserInvoices/:id",
          title: ({ params = {} }) => `Advertiser invoices edit ${String(params.id)}`,
        },
      ],
    },
    {
      path: "/redefinedUtmCampaign",
      redirect: "/redefinedUtmCampaign",
      title: "Redefined utm_campaigns",
    },
    {
      path: "/crm/mails",
      redirect: "/crm/mails",
      title: "Mails",
      children: [
        {
          path: "/crm/mails/create",
          title: "Create mail",
        },
        {
          path: "/crm/mails/:id",
          title: ({ params }) => `Edit mail: ${String(params.id)}`,
        },
      ],
    },
    {
      path: "/crm/templates",
      redirect: "/crm/templates",
      title: "Templates",
      children: [
        {
          path: "/crm/templates/create",
          title: "Create template",
        },
        {
          path: "/crm/templates/:id",
          title: ({ params }) => `Edit template: ${String(params.id)}`,
        },
      ],
    },
  ],
});
