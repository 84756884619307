/* eslint-disable import/max-dependencies */
import React, {
  useEffect,
  useState,
} from "react";
import environment from "environment";
import { BrowserRouter } from "react-router-dom";
import DocumentTitle from "react-document-title";
import {
  Loader,
} from "@fas/ui-core";
import { userApiService } from "./services/userApi";
import AppHeader from "./components/AppHeader";
import "typeface-roboto";
import RouterSlots from "./modules/components/RouterSlots";
import slots from "./slots";
import { TenantProvider } from "./modules/components/TenantContext";
import Providers from "./Providers";

const Component = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    userApiService.getUserInfo(environment.endpoints.get.login)
      .then(() => {
        setLoading(false);
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e);
      });
  }, []);

  return (
    <div className="App">
      <DocumentTitle title="CPA Admin Panel">
        <>
          {loading && <Loader loading size={35} mt={37} />}
          {!loading && (
            <BrowserRouter>
              <AppHeader />
              <AppRouter />
            </BrowserRouter>
          )}
        </>
      </DocumentTitle>
    </div>
  );
};

function AppRouter() {
  return (
    <TenantProvider>
      <RouterSlots slots={slots} />
    </TenantProvider>
  );
}

export default function App() {
  return (
    <Providers>
      <Component />
    </Providers>
  );
}
